exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-awards-about-us-tsx": () => import("./../../../src/pages/awards/about-us.tsx" /* webpackChunkName: "component---src-pages-awards-about-us-tsx" */),
  "component---src-pages-awards-categories-index-tsx": () => import("./../../../src/pages/awards/categories/index.tsx" /* webpackChunkName: "component---src-pages-awards-categories-index-tsx" */),
  "component---src-pages-awards-counties-index-tsx": () => import("./../../../src/pages/awards/counties/index.tsx" /* webpackChunkName: "component---src-pages-awards-counties-index-tsx" */),
  "component---src-pages-awards-faqs-tsx": () => import("./../../../src/pages/awards/faqs.tsx" /* webpackChunkName: "component---src-pages-awards-faqs-tsx" */),
  "component---src-pages-awards-finalists-tsx": () => import("./../../../src/pages/awards/finalists.tsx" /* webpackChunkName: "component---src-pages-awards-finalists-tsx" */),
  "component---src-pages-awards-get-involved-tsx": () => import("./../../../src/pages/awards/get-involved.tsx" /* webpackChunkName: "component---src-pages-awards-get-involved-tsx" */),
  "component---src-pages-awards-live-results-index-tsx": () => import("./../../../src/pages/awards/live-results/index.tsx" /* webpackChunkName: "component---src-pages-awards-live-results-index-tsx" */),
  "component---src-pages-awards-national-assets-tsx": () => import("./../../../src/pages/awards/national-assets.tsx" /* webpackChunkName: "component---src-pages-awards-national-assets-tsx" */),
  "component---src-pages-awards-national-results-tsx": () => import("./../../../src/pages/awards/national-results.tsx" /* webpackChunkName: "component---src-pages-awards-national-results-tsx" */),
  "component---src-pages-awards-nominate-index-tsx": () => import("./../../../src/pages/awards/nominate/index.tsx" /* webpackChunkName: "component---src-pages-awards-nominate-index-tsx" */),
  "component---src-pages-awards-terms-and-conditions-tsx": () => import("./../../../src/pages/awards/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-awards-terms-and-conditions-tsx" */),
  "component---src-pages-awards-vote-index-tsx": () => import("./../../../src/pages/awards/vote/index.tsx" /* webpackChunkName: "component---src-pages-awards-vote-index-tsx" */),
  "component---src-pages-awards-win-tsx": () => import("./../../../src/pages/awards/win.tsx" /* webpackChunkName: "component---src-pages-awards-win-tsx" */),
  "component---src-pages-awards-winners-tsx": () => import("./../../../src/pages/awards/winners.tsx" /* webpackChunkName: "component---src-pages-awards-winners-tsx" */),
  "component---src-pages-banner-sizes-tsx": () => import("./../../../src/pages/banner-sizes.tsx" /* webpackChunkName: "component---src-pages-banner-sizes-tsx" */),
  "component---src-pages-best-places-to-live-best-of-the-rest-tsx": () => import("./../../../src/pages/best-places-to-live/best-of-the-rest.tsx" /* webpackChunkName: "component---src-pages-best-places-to-live-best-of-the-rest-tsx" */),
  "component---src-pages-best-places-to-live-tsx": () => import("./../../../src/pages/best-places-to-live.tsx" /* webpackChunkName: "component---src-pages-best-places-to-live-tsx" */),
  "component---src-pages-dashboard-manage-subscriptions-tsx": () => import("./../../../src/pages/dashboard/manage-subscriptions.tsx" /* webpackChunkName: "component---src-pages-dashboard-manage-subscriptions-tsx" */),
  "component---src-pages-favourites-tsx": () => import("./../../../src/pages/favourites.tsx" /* webpackChunkName: "component---src-pages-favourites-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-in-the-press-tsx": () => import("./../../../src/pages/in-the-press.tsx" /* webpackChunkName: "component---src-pages-in-the-press-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-little-black-book-tsx": () => import("./../../../src/pages/little-black-book.tsx" /* webpackChunkName: "component---src-pages-little-black-book-tsx" */),
  "component---src-pages-log-in-tsx": () => import("./../../../src/pages/log-in.tsx" /* webpackChunkName: "component---src-pages-log-in-tsx" */),
  "component---src-pages-maintenance-tsx": () => import("./../../../src/pages/maintenance.tsx" /* webpackChunkName: "component---src-pages-maintenance-tsx" */),
  "component---src-pages-reader-treats-tsx": () => import("./../../../src/pages/reader-treats.tsx" /* webpackChunkName: "component---src-pages-reader-treats-tsx" */),
  "component---src-pages-school-reviews-index-tsx": () => import("./../../../src/pages/school-reviews/index.tsx" /* webpackChunkName: "component---src-pages-school-reviews-index-tsx" */),
  "component---src-pages-school-reviews-search-[category]-tsx": () => import("./../../../src/pages/school-reviews/search/[category].tsx" /* webpackChunkName: "component---src-pages-school-reviews-search-[category]-tsx" */),
  "component---src-pages-school-reviews-search-index-tsx": () => import("./../../../src/pages/school-reviews/search/index.tsx" /* webpackChunkName: "component---src-pages-school-reviews-search-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-weekend-escapes-index-tsx": () => import("./../../../src/pages/weekend-escapes/index.tsx" /* webpackChunkName: "component---src-pages-weekend-escapes-index-tsx" */),
  "component---src-pages-weekend-escapes-search-[category]-tsx": () => import("./../../../src/pages/weekend-escapes/search/[category].tsx" /* webpackChunkName: "component---src-pages-weekend-escapes-search-[category]-tsx" */),
  "component---src-pages-weekend-escapes-search-index-tsx": () => import("./../../../src/pages/weekend-escapes/search/index.tsx" /* webpackChunkName: "component---src-pages-weekend-escapes-search-index-tsx" */),
  "component---src-templates-archive-template-tsx": () => import("./../../../src/templates/archive-template.tsx" /* webpackChunkName: "component---src-templates-archive-template-tsx" */),
  "component---src-templates-awards-assets-template-tsx": () => import("./../../../src/templates/awards-assets-template.tsx" /* webpackChunkName: "component---src-templates-awards-assets-template-tsx" */),
  "component---src-templates-awards-content-template-tsx": () => import("./../../../src/templates/awards-content-template.tsx" /* webpackChunkName: "component---src-templates-awards-content-template-tsx" */),
  "component---src-templates-awards-intro-template-tsx": () => import("./../../../src/templates/awards-intro-template.tsx" /* webpackChunkName: "component---src-templates-awards-intro-template-tsx" */),
  "component---src-templates-awards-sponsor-template-tsx": () => import("./../../../src/templates/awards-sponsor-template.tsx" /* webpackChunkName: "component---src-templates-awards-sponsor-template-tsx" */),
  "component---src-templates-dashboard-template-tsx": () => import("./../../../src/templates/dashboard-template.tsx" /* webpackChunkName: "component---src-templates-dashboard-template-tsx" */),
  "component---src-templates-instagram-template-tsx": () => import("./../../../src/templates/instagram-template.tsx" /* webpackChunkName: "component---src-templates-instagram-template-tsx" */),
  "component---src-templates-littleblackbook-archive-template-tsx": () => import("./../../../src/templates/littleblackbook-archive-template.tsx" /* webpackChunkName: "component---src-templates-littleblackbook-archive-template-tsx" */),
  "component---src-templates-littleblackbook-post-template-tsx": () => import("./../../../src/templates/littleblackbook-post-template.tsx" /* webpackChunkName: "component---src-templates-littleblackbook-post-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/page-template.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-post-template-tsx": () => import("./../../../src/templates/post-template.tsx" /* webpackChunkName: "component---src-templates-post-template-tsx" */)
}

